$ {
  const { hasUnread, notifications } = state;
  const notificationsLen = notifications?.length ?? 0;
}
<div class:no-update="navbar-link is-arrowless">
  <span class:no-update="icon m-0">
    <i class=["fa-solid fa-bell fa-fw", hasUnread && "unread-badge"]></i>
  </span>
</div>
<div key="notifications" class:no-update="navbar-dropdown is-right is-boxed">
  <div no-update class="navbar-item is-justify-content-center">
    <p class="has-text-weight-bold">Notifications</p>
  </div>
  <hr no-update class="navbar-divider mb-0">
  <if(notifications && notificationsLen)>
    <for|userNotification, idx| of=notifications>
      $ const { notification, _id: unId, is_read } = userNotification;
      <div key=unId data-id=unId class=["navbar-item has-cursor-pointer py-3 navbar-item-notification", is_read && "is-read"] on-click("onNotificationClick", unId, idx)>
        <div class:no-update="is-flex is-flex-direction-row is-flex-grow-1 is-align-items-center user-notification">
          <div class:no-update="is-flex is-flex-grow-1 is-align-items-baseline mr-4 user-notification-title">
            <div class:no-update="navbar-item-notification-status">
              <if(!is_read)>
                <div aria-label="Unread notification" class="navbar-item-notification-status-unread"></div>
              </if>
            </div>
            <span no-update class="ml-2 is-pointer-events-none">${notification.title}</span>
          </div>
          <div class:no-update="user-notification-actions">
            <button no-update-body data-action="delete" data-tippy-user-notifications aria-label="Delete" data-tippy-content="Delete" data-tippy-position="bottom" class=["button is-small is-icon has-background-transparent", is_read && "has-text-grey"]>
              <span class="icon is-small m-0 p-0 is-pointer-events-none">
                <i class="fal fa-trash-alt fa-fw"></i>
              </span>
            </button>
            <if(is_read)>
              <button no-update-body data-action="unread" data-tippy-user-notifications aria-label="Mark as unread" data-tippy-content="Mark as unread" data-tippy-position="bottom" class=["button is-small is-icon has-background-transparent", is_read && "has-text-grey"]>
                <span class="icon is-small m-0 p-0 is-pointer-events-none">
                  <i class="fal fa-envelope-open fa-fw"></i>
                </span>
              </button>
            </if>
            <else>
              <button no-update-body data-action="read" data-tippy-user-notifications aria-label="Mark as read" data-tippy-content="Mark as read" data-tippy-position="bottom" class=["button is-small is-icon has-background-transparent", is_read && "has-text-grey"]>
                <span class="icon is-small m-0 p-0 is-pointer-events-none">
                  <i class="fal fa-envelope fa-fw"></i>
                </span>
              </button>
            </else>
          </div>
        </div>
      </div>
      <if(idx !== notificationsLen - 1)>
        <hr class="navbar-divider my-0 mx-4 p-0 navbar-divider-notification">
      </if>
    </for>
  </if>
  <else>
    <div no-update class="navbar-item py-5">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <span class="icon is-medium">
          <i class="fal fa-comment-alt fa-2x"></i>
        </span>
        <p class="mt-4">You don't have any notifications</p>
      </div>
    </div>
  </else>
  <hr no-update class="navbar-divider mt-0">
  <div no-update class="navbar-item is-justify-content-center">
    <a href="/notifications/">View all notifications</a>
  </div>
</div>
