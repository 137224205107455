import { createId } from '@paralleldrive/cuid2';

$ {
  let elementId;
  let resultCounter = 0;

  const formId = createId();

  const { hits, query, selected } = state;
}

<button class="button is-small is-white has-text-grey" data-hotkey="/" on-click("handleSearchDocsClick")>
  Search docs
  <kbd class="has-border-grey has-border-radius ml-5">
    <span class="icon is-small m-0" title="Forward slash">
      <i aria-hidden="true" class="fa-light fa-slash-forward fa-fw"></i>
    </span>
  </kbd>
</button>

<app-modal key="modal" on-modal-open("handleModalOpen") on-modal-close("handleModalClose")>
  <@title>
    Search documentation
  </@title>
  <@body>
    <div key="container" tabindex="0" class="has-outline-none" on-keydown("handleKeyDown")>
      <header>
        <form key="form" id:scoped=formId on-input("handleFormInput") on-submit("handleFormSubmit")>
          <div class:no-update="field">
            $ elementId = createId();
            <label no-update class="label" for:scoped=elementId>Enter search criteria</label>
            <div class:no-update="control">
              <input no-update id:scoped=elementId key="search" name="search" class="input" type="search" />
            </div>
            <p no-update class="help">
              When selected, results will open in a new window.
            </p>
          </div>
        </form>
      </header>
      <if(query)>
        <main key="main" class:no-update="mt-5">
          <if(hits.size)>
            <div key="searchBox" class:no-update="doc-search-box is-overflow-y-auto is-overflow-x-hidden pr-1" on-mouseover("handleMouseOver")>
              <for|[header, entries], idx| of=hits.entries()>
                <section key=`hit-${idx}` class:no-update="doc-search-section">
                  <h3 class:no-update="title is-6 has-text-weight-semibold mb-3">${header}</h3>
                  <ul>
                    <for|entry| of=entries>
                      $ {
                        const resultIndex = component.createIndexKey(resultCounter);
                        const isSelected = resultIndex === selected;
                        const {
                          content,
                          hierarchy_lvl1,
                          hierarchy_lvl2,
                          hierarchy_lvl3,
                          hierarchy_lvl4,
                          hierarchy_lvl5,
                          hierarchy_lvl6,
                          hierarchy_radio_lvl0,
                          hierarchy_radio_lvl1,
                          hierarchy_radio_lvl2,
                          hierarchy_radio_lvl3,
                          hierarchy_radio_lvl4,
                          hierarchy_radio_lvl5,
                          url
                        } = entry;

                        const firstHierarchy = hierarchy_lvl1 || hierarchy_lvl2 || hierarchy_lvl3 || hierarchy_lvl4 || hierarchy_lvl5 || hierarchy_lvl6;
                        const secondHierarchy = hierarchy_radio_lvl0 || hierarchy_radio_lvl1 || hierarchy_radio_lvl2 || hierarchy_radio_lvl3 || hierarchy_radio_lvl4 || hierarchy_radio_lvl5;
                      }
                      <li tabindex="0" key=resultIndex data-url=url data-result=resultIndex data-index=resultCounter aria-selected=(isSelected ? "true" : "false") class=["px-4 py-4 has-border-grey-lighter has-border-radius is-clickable", isSelected && "is-selected"]>
                        <a href=url target="_blank" tabindex="-1">
                          <div class="is-flex is-flex-direction-column">
                            <p>
                              <span>${firstHierarchy}</span>
                              <if(secondHierarchy)>
                                <span class="mx-2">&#124;</span>
                                <span>${secondHierarchy}</span>
                              </if>
                            </p>
                            <if(content)>
                              <p class="is-size-7 has-text-grey">
                                ${content}&hellip;
                              </p>
                            </if>
                          </div>
                        </a>
                      </li>
                      $ resultCounter = resultCounter + 1;
                    </for>
                  </ul>
                </section>
              </for>
            </div>
          </if>
          <else>
            <p class="has-text-centered">No results found for "${query}".</p>
          </else>
        </main>
      </if>
    </div>
  </@body>
  <@footer>
    <div class="is-flex is-align-items-center is-justify-content-space-between is-flex-grow-1">
      <div>
        <div class="buttons is-left">
          <button class="button is-white" on-click("handleClose")>Close</button>
        </div>
      </div>
      <div class="is-flex is-flex-direction-row has-column-gap-2">
        <div class="is-flex is-align-items-center has-column-gap-1">
          <span class="help mt-0">
            <kbd class="has-border-grey has-border-radius">
              <span class="icon is-small m-0" title="Enter">
                <i aria-hidden="true" class="fa-light fa-arrow-turn-down-left fa-fw"></i>
              </span>
            </kbd>
          </span>
          <span class="help m-0">to select</span>
        </div>
        <div class="is-flex is-align-items-center has-column-gap-1">
          <span class="help mt-0">
            <kbd class="has-border-grey has-border-radius">
              <span class="icon is-small m-0" title="Arrow up">
                <i aria-hidden="true" class="fa-light fa-arrow-up fa-fw"></i>
              </span>
            </kbd>
          </span>
          <span class="help mt-0">
            <kbd class="has-border-grey has-border-radius">
              <span class="icon is-small m-0" title="Arrow down">
                <i aria-hidden="true" class="fa-light fa-arrow-down fa-fw"></i>
              </span>
            </kbd>
          </span>
          <span class="help m-0">to navigate</span>
        </div>
      </div>
    </div>
  </@footer>
</app-modal>
