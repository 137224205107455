class Component {
  onCreate(input) {
    const { isOpen } = input;
    this.state = {
      isOpen: Boolean(isOpen),
      disableClose: false,
    };
    this.listeners = null;
  }

  onMount() {
    const { isOpen } = this.state;
    if (isOpen) {
      this.setIsClipped();
      this.setUpListeners();
    }
  }

  onKeyDown(event) {
    const { isOpen, disableClose } = this.state;
    const { key } = event;

    if (disableClose) {
      return;
    }

    if (isOpen && key === 'Escape') {
      this.onClose(event);
      return;
    }
  }

  setIsClipped() {
    document.querySelector('html').classList.add('is-clipped');
  }

  removeIsClipped() {
    document.querySelector('html').classList.remove('is-clipped');
  }

  setUpListeners() {
    this.listeners = this.subscribeTo(document).on(
      'keydown',
      this.onKeyDown.bind(this)
    );
  }

  destroyListeners() {
    if (this.listeners) {
      this.listeners.removeAllListeners();
      this.listeners = null;
    }
  }

  open() {
    this.setState('isOpen', true);

    this.setIsClipped();
    this.setUpListeners();
  }

  close() {
    if (this.state.disableClose) {
      return;
    }

    this.setState('isOpen', false);

    this.removeIsClipped();
    this.destroyListeners();
  }

  onClose(event) {
    // skipcq: JS-0093
    event && event.stopPropagation();

    if (this.state.disableClose) {
      return;
    }

    this.close();
    setTimeout(this.emit.bind(this, 'modal-close', event));
  }

  onOpen() {
    this.open();
    setTimeout(this.emit.bind(this, 'modal-open'));
  }

  disableClose() {
    this.setState('disableClose', true);
  }

  enableClose() {
    this.setState('disableClose', false);
  }
}

export default Component;
