$ {
  const { isOpen } = state;
  const { extraClasses, body, footer, title, excludeFooter } = input;
}

<div class=["modal", isOpen && "is-active", extraClasses && extraClasses]>
  <div no-update class="modal-background"></div>
  <div class:no-update="modal-card">
    <header class:no-update="modal-card-head">
      <if(title)>
        <p class:no-update=["modal-card-title"]>
          <${title} />
        </p>
      </if>
      <button no-update-body class:no-update="button is-small is-white is-rounded is-modal-close" aria-label="Close dialog" on-click("onClose")>
        <span aria-hidden=true class="icon">
          <i class="fal fa-times"></i>
        </span>
      </button>
    </header>
    <section class:no-update="modal-card-body">
      <${body} />
    </section>
    <footer class:no-update=[!excludeFooter && "modal-card-foot"]>
      <if(!excludeFooter)>
        <if(footer)>
          <${footer} />
        </if>
        <else>
          <div class:no-update="buttons is-right">
            <button no-update-body class:no-update="button is-white" on-click("onClose")>Cancel</button>
          </div>
        </else>
      </if>
    </footer>
  </div>
</div>
