import { MeiliSearch } from 'meilisearch';

import { debounce } from '../../lib/debounce.mjs';

export default class Component {
  onCreate(input) {
    const { config } = input;
    this.state = {
      config,
      query: '',
      hits: new Map(),
      selected: this.createIndexKey(0),
      selectedIndex: 0,
      maxIndex: 0,
    };
  }

  onMount() {
    const { config } = this.state;

    this.modalComponent = this.getComponent('modal');

    setTimeout(() => {
      const { host, apiKey, indexUid } = config;

      this.searchClient = new MeiliSearch({ host, apiKey });
      this.searchIndex = this.searchClient.index(indexUid);
    });
  }

  handleSearchDocsClick() {
    this.modalComponent.onOpen();
  }

  handleClose() {
    this.modalComponent.onClose();
  }

  handleModalOpen() {
    this.getEl('search')?.focus();
  }

  handleModalClose() {
    this.reset();
  }

  reset() {
    this.getEl('form')?.reset();
    this.setState({
      query: '',
      hits: new Map(),
      selected: this.createIndexKey(0),
      selectedIndex: 0,
      maxIndex: 0,
    });
  }

  /**
   * @param {Number|String} index
   * @returns {String}
   */
  createIndexKey(index) {
    return 'result-' + index;
  }

  /**
   *
   * @param {String|Number} index
   */
  setMaxIndex(index) {
    this.setState({ maxIndex: Number.parseInt(index, 10) });
  }

  async handleSearch(query) {
    const { hits, total } = Component.parseSearchResult(
      await this.searchIndex.search(query),
    );

    this.setState({ query, hits, maxIndex: total ? total - 1 : 0 });
    this.setStateDirty('hits');
  }

  /**
   * Clear non-ASCII characters from a string.
   * @param {String} str
   * @returns {String}
   */
  static clearCharacters(str) {
    if (!str) {
      return '';
    }

    // eslint-disable-next-line no-control-regex
    return str.replace(/[^\x00-\x7F]/g, '').trim();
  }

  /**
   * Parse and group the results from the search query.
   * @param {Object} data The search result
   * @returns {Object<Map, Number>}
   */
  static parseSearchResult(data) {
    const { hits } = data;

    const results = new Map();

    if (hits.length) {
      for (const hit of hits) {
        const {
          content,
          hierarchy_lvl0,
          hierarchy_lvl1,
          hierarchy_lvl2,
          hierarchy_lvl3,
          hierarchy_lvl4,
          hierarchy_lvl5,
          hierarchy_lvl6,
          hierarchy_radio_lvl0,
          hierarchy_radio_lvl1,
          hierarchy_radio_lvl2,
          hierarchy_radio_lvl3,
          hierarchy_radio_lvl4,
          hierarchy_radio_lvl5,
          url,
          objectID,
        } = hit;

        const title = Component.clearCharacters(hierarchy_lvl0);

        let entry = results.get(title);
        if (!entry) {
          results.set(title, (entry = []));
        }

        entry.push({
          content: content && content.slice(0, 100),
          hierarchy_lvl1: Component.clearCharacters(
            hierarchy_lvl1 || hierarchy_lvl0,
          ),
          hierarchy_lvl2: Component.clearCharacters(hierarchy_lvl2),
          hierarchy_lvl3: Component.clearCharacters(hierarchy_lvl3),
          hierarchy_lvl4: Component.clearCharacters(hierarchy_lvl4),
          hierarchy_lvl5: Component.clearCharacters(hierarchy_lvl5),
          hierarchy_lvl6: Component.clearCharacters(hierarchy_lvl6),
          hierarchy_radio_lvl0: Component.clearCharacters(hierarchy_radio_lvl0),
          hierarchy_radio_lvl1: Component.clearCharacters(hierarchy_radio_lvl1),
          hierarchy_radio_lvl2: Component.clearCharacters(hierarchy_radio_lvl2),
          hierarchy_radio_lvl3: Component.clearCharacters(hierarchy_radio_lvl3),
          hierarchy_radio_lvl4: Component.clearCharacters(hierarchy_radio_lvl4),
          hierarchy_radio_lvl5: Component.clearCharacters(hierarchy_radio_lvl5),
          url,
          objectID,
        });
      }
    }

    return { hits: results, total: hits.length };
  }

  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    return;
  }

  handleKeyDown(event) {
    const { hits, selectedIndex } = this.state;
    const { key } = event;

    if (hits.size) {
      if (key === 'ArrowDown') {
        event.preventDefault();

        const { maxIndex } = this.state;

        const index = selectedIndex + 1;
        const newIndex = index > maxIndex ? maxIndex : index;
        const selectedKey = this.createIndexKey(newIndex);

        this.setState({
          selectedIndex: newIndex,
          selected: selectedKey,
        });

        setTimeout(
          (key) => {
            this.getEl(key).scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          },
          1,
          selectedKey,
        );

        return;
      }

      if (key === 'ArrowUp') {
        event.preventDefault();

        if (selectedIndex === 0) {
          return;
        }

        const index = selectedIndex - 1;
        const newIndex = index < 0 ? 0 : index;
        const selectedKey = this.createIndexKey(newIndex);

        this.setState({
          selectedIndex: newIndex,
          selected: selectedKey,
        });

        setTimeout(
          (key) => {
            this.getEl(key).scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            });
          },
          1,
          selectedKey,
        );

        return;
      }

      if (key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        const { selected } = this.state;

        const linkElement = this.getEl(selected);
        if (linkElement) {
          const url = linkElement.dataset.url;
          window.open(url, '_blank');
        }

        return;
      }
    }
  }
}

Component.prototype.handleFormInput = debounce(async function (event) {
  const { target } = event;
  const { nodeName, name, value } = target;

  if (nodeName === 'INPUT' && name === 'search') {
    event.stopPropagation();
    event.preventDefault();

    const query = value.trim();
    if (query) {
      await this.handleSearch(query);
    } else {
      this.setState({
        hits: new Map(),
        maxIndex: 0,
        query: '',
        selected: this.createIndexKey(0),
        selectedIndex: 0,
      });
    }
  }
}, 200);

Component.prototype.handleMouseOver = function (event) {
  const { target } = event;
  if (target.nodeName === 'LI') {
    if (target.dataset.result !== this.state.selected) {
      this.setState({
        selected: target.dataset.result,
        selectedIndex: Number.parseInt(target.dataset.index, 10),
      });
    }
  }
};
