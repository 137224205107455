/**
 * Debounce the passed function.
 * @method
 * @param  {Function} func The function to debounce
 * @param  {Number} wait Milliseconds to wait before executing the function
 * @param  {Boolean} immediate If the function needs to be executed immediately
 * @return {Function}
 */
export function debounce(func, wait, immediate) {
  let timeout;

  return function () {
    const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
}

export default debounce;
